let percentage={};

percentage.discount = (msp,sellingPrice)=>{
    if (msp === 0) {
        return "newPrice" === 0 ? 0 : 100; // Handle special case when oldPrice is 0
      }
      const percentageChange = ((msp - sellingPrice) / msp) * 100;
     let x = ~~percentageChange;
      return x;
}


export default percentage;
