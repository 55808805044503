import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./StickyLayout.css";

const StickyHeaderFooter = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="layout-container">
      {/* Header */}
      <header className="header">
        <div className="header-content">
          {/* <h1 className="app-title">E-Shop</h1> */}
          <div className="profile-section">
            <img
              src="/treelix-logo.png" // Replace with your profile image
              alt="Profile"
              className="profile-pic"
            />
            <span className="profile-name">Treelix</span>
          </div>

          {/* Navigation */}
          <nav className={`nav-links ${menuOpen ? "open" : ""}`}>
            <li>
              <NavLink to="/" exact="true" activeclassname="active-link">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/products" activeclassname="active-link">
                Products
              </NavLink>
            </li>

            <li>
              <NavLink to="/benefits" activeclassname="active-link">
              Benefits of Microgreens
              </NavLink>
            </li>

            <li>
              <NavLink to="/blog" activeclassname="active-link">
                Blogs
              </NavLink>
            </li>
            <li>
                <NavLink to="/contact" activeclassname="active-link">
                  Contact
                </NavLink>
              </li>
            <li>

              <NavLink to="/about" activeclassname="active-link">
                About
              </NavLink>
            </li>
            {/* <a href="#home">Home</a>
          <a href="#products">Products</a>
          <a href="#about">About</a>
          <a href="#contact">Contact</a> */}
          </nav>

          <div className="header-actions">
            <button className="menu-toggle" onClick={toggleMenu}>
              ☰
            </button>
            <div className="profile-section">
              <img
                src="/avtar.png" // Replace with your profile image
                alt="Profile"
                className="profile-pic"
              />
              {/* <span className="profile-name">Abhi</span> */}
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}

      {/* <div className="">
            <img
              src="./images/microgreen.png" // Replace with your profile image
              alt="Profile"
              className=""
            />
            <span className="profile-name">Treelix</span>
          </div> */}
      <main className="main-content">{children}</main>

      {/* Footer */}
      <footer className="footer">
        <p>
          &copy; {new Date().getFullYear()} TREELIX AGRITECH & INDUSTRIES
          PRIVATE LIMITED . All Rights Reserved.
        </p>
        <img
          src="/fqrcode.png" // Replace with your profile image
          alt="Profile"
          className="footer-fqrcode"
        />
        <img
          src="/fssailogo.png" // Replace with your profile image
          alt="Profile"
          className="footer-fssai"
        />
        <img
          src="/1223455b433a379d576Jaivik-Bharat-logo_Final.png" // Replace with your profile image
          alt="Profile"
          className="footer-Jaivik-Bharat-logo"
        />

        <img
          src="/1912835b433a59c2cccshare-food.png" // Replace with your profile image
          alt="Profile"
          className="footer-share-food"
        />
      </footer>
    </div>
  );
};

export default StickyHeaderFooter;
