import React from 'react';

const MicrogreensInfo = () => {
  return (
    <div className="blogContainer" style={{lineHeight:"30px"}}>
      <h2>Health Benefits of Microgreens</h2>
      <ul>
        <li>
        {/* <img
              src="/images/Disease.jpeg" // Replace with your profile image
              alt="Profile"
              className=""
              style={{width:"300px"}}
            /> */}
          <b>Heart Disease:</b> Microgreens are rich in polyphenols, antioxidants linked to a lower risk of heart disease. They may lower triglycerides and "bad" LDL cholesterol.
        </li>
        <li>
          <b>Alzheimer's Disease:</b> Antioxidant-rich foods, including those high in polyphenols, may reduce the risk of Alzheimer's disease.
        </li>
        <li>
          <b>Diabetes:</b> Antioxidants may help prevent sugar from improperly entering cells. Studies show fenugreek microgreens enhance cellular sugar uptake.
        </li>
        <li>
          <b>Cancer:</b> Antioxidant-rich fruits and vegetables, especially those rich in polyphenols, may lower the risk of various cancers.
        </li>
        <li>
          <b>Chronic Disease:</b> Microgreens and vegetable intake reduce inflammation markers and lower the risk of several cancers.
        </li>
      </ul>

      <h2>Most Popular Variety of Microgreens</h2>
      <ul>
        <li>Cauliflower</li>
        <li>Broccoli</li>
        <li>Cabbage</li>
        <li>Radish</li>
        <li>Carrot</li>
        <li>Garlic</li>
        <li>Onion</li>
        <li>Amaranths</li>
        <li>Beetroot</li>
        <li>Spinach</li>
        <li>Melon</li>
        <li>Cucumber</li>
        <li>Squash</li>
        <li>Cereals: Rice, Oats, Wheat, Corn, Barley</li>
        <li>Legumes: Chickpeas, Beans, Lentils</li>
      </ul>
      <p>
        They vary in taste, ranging from neutral to spicy, slightly sour, or even bitter.
      </p>

      <h2>Rainbow of Microgreens</h2>
      <ul>
        <li>
          <b>Red:</b> Contain lycopene, a powerful antioxidant.
        </li>
        <li>
          <b>Orange & Yellow:</b> Contain carotenoids like beta-carotene and lutein, converted to vitamin A.
        </li>
        <li>
          <b>Blue & Purple:</b> Contain anthocyanin, which protects cells from damage.
        </li>
        <li>
          <b>Green:</b> Contain a wide range of phytochemicals, including saponins, indoles, and carotenoids.
        </li>
        <li>
          <b>Brown & White:</b> Like garlic, contain allicin with antibacterial properties.
        </li>
      </ul>

      {/* Add information about nutritive values here */}
    </div>
  );
};

export default MicrogreensInfo;