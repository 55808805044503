// import React from 'react';
// import PropTypes from 'prop-types';
// import { BlockPicker } from 'react-color';


// class ColorPic extends Component {
//   static propTypes = {
//     expanded: PropTypes.bool,
//     onExpandEvent: PropTypes.func,
//     onChange: PropTypes.func,
//     currentState: PropTypes.object,
//   };

//   stopPropagation = (event) => {
//     event.stopPropagation();
//   };

//   onChange = (color) => {
//     const { onChange } = this.props;
//     onChange('color', color.hex);
//   }

//   renderModal = () => {
//     const { color } = this.props.currentState;
//     return (
//       <div
//         onClick={this.stopPropagation}
//       >
//         <BlockPicker color={color} onChangeComplete={this.onChange} />
//       </div>
//     );
//   };

//   render() {
//     const { expanded, onExpandEvent } = this.props;
//     return (
//       <div
//         aria-haspopup="true"
//         aria-expanded={expanded}
//         aria-label="rdw-color-picker"
//       >
//         <div
//           onClick={onExpandEvent}
//         >
//           <img
//             src={icon}
//             alt=""
//           />
//         </div>
//         {expanded ? this.renderModal() : undefined}
//       </div>
//     );
//   }
// }
// export default ColorPic;

import React from 'react';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color'; // Assuming BlockPicker is being used
// import icon from './path-to-your-icon.png'; // Replace with the actual path to the icon

const ColorPic = ({ expanded, onExpandEvent, onChange, currentState }) => {
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleColorChange = (color) => {
    onChange('color', color.hex);
  };

  const renderModal = () => {
    const { color } = currentState;
    return (
      <div onClick={stopPropagation}>
        <BlockPicker color={color} onChangeComplete={handleColorChange} />
      </div>
    );
  };

  return (
    <div aria-haspopup="true" aria-expanded={expanded} aria-label="rdw-color-picker">
      <div onClick={onExpandEvent}>
        {/* <img src={icon} alt="" /> */}
      </div>
      {expanded ? renderModal() : null}
    </div>
  );
};

ColorPic.propTypes = {
  expanded: PropTypes.bool,
  onExpandEvent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
};

export default ColorPic;

