
import { configureStore } from '@reduxjs/toolkit'
import apiDataReducer from './reducer/Home'; // Import your slice(s)
// import counterReducer from '../features/counter/counterSlice'

export const store = configureStore({
  reducer: {
    apiData: apiDataReducer,
  },
})

