import React from "react";
import "./LeafLoading.css";

const LeafLoading = () => {
  return (
    <div className="leaf-loader-container">
      <div className="leaf-loader">
        <div className="leaf"></div>
        <div className="leaf"></div>
        <div className="leaf"></div>
        <div className="leaf"></div>
      </div>
      <p>Loading...</p>
    </div>
  );
};

export default LeafLoading;
