import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  saveOrder
} from "../../../store/reducer/Home";
const OrderSummary = ({ order }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { customerFormData, selectedData } = useSelector(
    (state) => state.apiData
  );


  const calculateTotal = () => {
    let total = 0;
    // products.forEach((product) => {
    //   total += product.price * product.quantity;
    // });
    return total;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      ...selectedData,
      phone: customerFormData["phone"]
    }
    dispatch(saveOrder(payload))
      .then((res) => {
        console.error("success res:", res);
        if (res.payload.code == 200) {
          navigate("/orderSuccess");
        } else if (res.payload.code == 401) {

        }

      })
      .catch((error) => {
        console.error("Error fetching location:", error);
      });
  };

  const calculateWithDicTotal = (price) => {
 return Math.floor(price); 
  }


  console.log({ ...customerFormData, ...selectedData })

  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Order Summary
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Shipping Address</Typography>
          <List>
            <ListItem>
              <ListItemText primary={customerFormData.fullName} />
            </ListItem>
            <ListItem>
              <ListItemText primary={customerFormData.phone} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`${customerFormData.city}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${customerFormData.state}, ${customerFormData.zipCode}`}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6">Order Summary</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={`${selectedData.productName} x ${selectedData.selectedWeight}`}
              />
            </ListItem>

            <ListItem>
              <ListItemText primary={`Subscription`} />
              <ListItemText primary={`${selectedData.selectedSubs?.repeat}`} />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={selectedData.selectedSubs?.description}
              />

            </ListItem>
            <ListItem>
              {/* <ListItemText primary={`Delivery`} /> */}
              Delivery<s className="actual-price">₹50</s>  <span className="off-percentage">FREE</span>
            </ListItem>

            <ListItem>
              <ListItemText primary="Payment" />
              <ListItemText primary={`COD`} />
            </ListItem>

            <ListItem>
              <ListItemText primary="Price" />
              <ListItemText primary={`₹${calculateWithDicTotal(selectedData.maxP)}`} />
              {/* <ListItemText primary={`₹${calculateTotal().toFixed(2)}`} /> */}
            </ListItem>

            <ListItem>
              <ListItemText primary="Discount" />
              <ListItemText primary={`₹${selectedData.totalDiscount}`} />
              {/* <ListItemText primary={`₹${calculateTotal().toFixed(2)}`} /> */}
            </ListItem>
            {/* Add shipping, tax, and discount calculations here */}
            <ListItem>
              <ListItemText primary="Total" />
              <ListItemText primary={`₹${selectedData.totalPrice}`} />
            </ListItem>
          </List>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#2f7d31" }}
              onClick={handleSubmit}
              fullWidth
            >
              Place Order
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderSummary;
