import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchBlogs } from "../../../store/reducer/Home";
import HtmlReactParserComponent from "../../../components/HtmlReactParserComponent";
import LeafLoading from "../../../common/LeafLoading";

function BlogList() {
  const dispatch = useDispatch();
  const { loading, blogs } = useSelector((state) => state.apiData);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: location.pathname + location.search + location.hash,
    });
  }, [location]);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://app-hvpdly3esq-uc.a.run.app/v1/api/blogs"
  //       ); // Replace with your API endpoint
  //       setBlogs(response.data);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error loading data: {error.message}</div>;
  // }

  return (
    <div className="blogContainer">
      {loading == "pending" && <LeafLoading />}
      {blogs?.map((blog) => (
        <div
          key={blog._id}
          style={{
            borderBottom: "1px solid #ccc",
            padding: "10px",
            backgroundColor: `rgba(${Math.floor(
              Math.random() * 256
            )}, ${Math.floor(Math.random() * 256)}, ${Math.floor(
              Math.random() * 256
            )}, 0.1)`,
          }}
        >
          <HtmlReactParserComponent
            htmlContent={blog.content}
            title={blog.title}
          />
        </div>
      ))}
    </div>
  );
}

export default BlogList;
