import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LeafLoading from "../../../common/LeafLoading";
import {
  fetchByidMicrogreen,
  setCustomerFormData,
  saveUser
} from "../../../store/reducer/Home";

const CustomerForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  // const [coordinates, setCoordinates] = useState([]);

  
  const [formData, setFormData] = useState({
    fullName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    zipCode: "",
    city: "",
    state: "",

  });
  const { customerFormData,userData } = useSelector((state) => state.apiData);

  const [loadingLocation, setLoadingLocation] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const success = (position) => {
      const { latitude, longitude } = position.coords;
      let lat=[latitude, longitude];
      console.log('lat',lat)
      const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDks7ClHsi1SxSTKHhODu56_3JoCPkpBMc`;

      axios
        .get(geocodeUrl)
        .then((response) => {
          setLoadingLocation(false);
          const addressFormated = response.data.results[0].formatted_address;
          const addressComponents = response.data.results[0].address_components;
          const cityComponent = addressComponents.find((component) =>
            component.types.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          );
          const zipCodeComponent = addressComponents.find((component) =>
            component.types.includes("postal_code")
          );

          setFormData({
            zipCode: zipCodeComponent?.long_name || "",
            state: stateComponent?.long_name || "",
            city: cityComponent?.long_name || "",
            address: addressFormated || "",
            coordinates:lat
          });
        })
        .catch((error) => {
          setError("Error fetching location.");
          console.error("Error fetching location:", error);
        });
    };

    const errorCallback = (error) => {
      setError("Unable to retrieve location.");
      console.error("Error getting location:", error);
    };

    if (navigator.geolocation) {
      setLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(success, errorCallback);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  }, []);

//   useEffect(() => {
// console.log('userData---fetch',userData)
//   }, [dispatch,userData]); 

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here (e.g., send data to server)
    dispatch(setCustomerFormData(formData));
    dispatch(saveUser(formData))
    .then((res) => {
      console.error("success res:", res);
      console.log('userData----res',userData.code)
      if(userData.code==200){
        navigate("/orderSummary");
      }else if(userData.code==401){
        navigate("/orderSummary");
      }

    })
    .catch((error) => {
      console.error("Error fetching location:", error);
    });

    // navigate("/orderSummary");
  };


  return (
    <Container component="main" maxWidth="xs">
      {loadingLocation && <LeafLoading />}
      <Typography variant="h5" align="center" gutterBottom>
        Enter your delivery information
      </Typography>
    { !loadingLocation &&<form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="fullName"
              label="Name"
              name="fullName"
              autoComplete="given-name"
              value={formData.fullName}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="phone"
              label="Phone Number"
              name="phone"
              autoComplete="tel"
              value={formData.phone}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="society"
              label="Society / House"
              name="Society"
              value={formData.society}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="flatNo"
              label="Flat Number"
              name="flatNo"
              autoComplete="flatNo"
              value={formData.flatNo}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="state"
              label="State"
              name="state"
              autoComplete="state"
              value={formData.state}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="city"
              label="City"
              name="city"
              autoComplete="city"
              value={formData.city}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="zipCode"
              label="Pin Code"
              name="zipCode"
              autoComplete="zipCode"
              value={formData.zipCode}
              onChange={handleChange}
              style={{ backgroundColor: "#fff" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="address"
              label="Shipping Address"
              name="address"
              autoComplete="shipping"
              style={{ backgroundColor: "#fff" }}
              value={formData.address}
              multiline
              rows={4}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox checked={true} onChange={() => setChecked(true)} />
          }
          label="COD: Pay after delivery "
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          style={{ backgroundColor: "#2f7d31" }}
          sx={{ mt: 3, mb: 2 }}
          //   onClick={handleSaveNext}
        >
          SAVE & NEXT
        </Button>
      </form>}
    </Container>
  );
};

export default CustomerForm;
