import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import styled from "styled-components";
import {
  Grid2,
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import BreadcrumbNavigation from "../../../common/BreadcrumbNavigation";
import "./ProductDescription.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BoltIcon from "@mui/icons-material/Bolt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  fetchByidMicrogreen,
  setExampleData,
} from "../../../store/reducer/Home";
import LeafLoading from "../../../common/LeafLoading";
import { default as percentage } from '../../../utils/percentage';
import HtmlReactParserComponent from "../../../components/HtmlReactParserComponent";


const ProductDescription = ({ product }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [getWeight, setGetWeight] = useState("");
  const [getSubscriptionSystem, setGetSubscriptionSystem] = useState(null);
  const [proData, setData] = useState({});

  const { sData } = useSelector((state) => state.apiData);
  const { loading } = useSelector((state) => state.apiData);

  const dispatch = useDispatch();

  const { selectedData } = useSelector((state) => state.apiData);

  let url = window.location.pathname;
  let id = url.split("/").pop();

  console.log('id',id)

  useEffect(() => {
    if (id && !state || id!=state?._id) {
      dispatch(fetchByidMicrogreen(id));
    }
  }, [id, dispatch]);

  // useEffect(() => {

  //   if(state==null||state=='null'){
  //     let url=window.location.pathname;
  //     let id =url.split("/").pop()
  //     console.log('aaaaaaaaaa',url.split("/").pop());
  //     dispatch(fetchByidMicrogreen(id));
  //   }

  // }, [sData]);

  useEffect(() => {
    let sd = state ? state : sData;

    if (state || sData) {
      const dt = sd.weight?.find((w) => w?.default);
      const ss = sd.subscriptionSystem.find((w) => w?.default);
      setGetWeight(dt.weight);
      setGetSubscriptionSystem(ss);
      setData(sd);
    }
  }, [sData]);

  const breadcrumbItems = [
    { label: "Home", path: "/" },
    // { label: 'Shop', path: '/shop' },
    // { label: 'Microgreens', path: '/shop/microgreens' },
    // { label: 'Details', path: '/shop/microgreens/details' },
  ];

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: location.pathname + location.search + location.hash,
    });
  }, [location]);

  const handleNavigate = (path) => {
    // console.log("Navigating to:", path);
    navigate(path);
  };

  const handleAddtoCart = (path) => {
    // console.log("Navigating to:", path);
    // navigate("/customerinfo");
    let cartItem = {
      ...proData,
      selectedWeight: getWeight,
      selectedSubs: getSubscriptionSystem,
      totalPrice: calculateWithDicTotal(proData),
      totalDiscount: (calculateTotal(proData).toFixed(2) - calculateWithDicTotal(proData)),
      maxP: (calculateTotal(proData).toFixed(2)) + 50
    };
    dispatch(setExampleData(cartItem));
  };

  const handleSaveNext = (path) => {
    console.log("Navigating to:", path);
    navigate("/customerinfo");
    let cartItem = {
      ...proData,
      selectedWeight: getWeight,
      selectedSubs: getSubscriptionSystem,
      totalPrice: calculateWithDicTotal(proData),
      totalDiscount: (calculateTotal(proData).toFixed(2) - calculateWithDicTotal(proData)),
      maxP: (calculateTotal(proData).toFixed(2)) + 50
    };
    dispatch(setExampleData(cartItem));
  };

  // if (loading == 'pending') {
  //   return (
  //     <LeafLoading/>
  //   );
  // }

  const calculateWithDicTotal = (proData) => {
    let total = number(proData?.pricePerGM) * number(getWeight);
    // if (getSubscriptionSystem?.repeat == "Per-Day") {
    //   total = total - 10;
    // } else if (getSubscriptionSystem?.repeat == "3-Day Weekly") {
    //   total = total - 5;
    // }
    return total - 1;
  };

  const calculateTotal = (proData) => {
    let Price = proData?.pricePerGM;

    let pgPrice = number(Price) + 1;
    let maxPrice = number(pgPrice) * number(getWeight)


    return maxPrice;
  };
  const number = (price) => {
    return Math.floor(price);
  }

  const percentageVal = (proData) => {
    let Price = proData?.pricePerGM;
    let totalPrice = number(Price) * number(getWeight);

    let pgPrice = number(Price) + 1;
    let maxPrice = number(pgPrice) * number(getWeight)

    // console.log('maxPrice', Math.floor(maxPrice),'totalPrice',Math.floor(totalPrice))
    // return percentage.discount(100, 90);

    return percentage.discount(Math.floor(maxPrice), Math.floor(totalPrice));
  };

  const calculateOff = (subs) => {

    let totalPrice = proData?.pricePerGM;

    let totall = totalPrice * getWeight;
    let total = 0;

    if (subs?.repeat == "Per-Day") {
      total += 10;
    } else if (subs?.repeat == "3-Day Weekly") {
      total += 5;
    }


    return total;
  };
  // console.log('calculateTotal', (calculateTotal(proData).toFixed(2)-calculateWithDicTotal(proData)))

  return (
    <>
      {loading == "pending" && <LeafLoading />}
      <BreadcrumbNavigation
        items={breadcrumbItems}
        onNavigate={handleNavigate}
      />
      <Box sx={{ flexGrow: 1 }}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <img src={proData?.image} alt={proData.name} className="product-image" />

            <Grid2 container spacing={1} className="button-container">
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Button
                  size="large"
                  variant="outlined"
                  fullWidth
                  startIcon={<ShoppingCartIcon />}
                  onClick={handleAddtoCart}
                >
                  ADD TO CART
                </Button>
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Button
                  style={{ backgroundColor: "#2f7d31", color: "#fff" }}
                  size="large"
                  variant="outlined"
                  fullWidth
                  startIcon={<BoltIcon />}
                  onClick={handleSaveNext}
                >
                  Subscribe NOW
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography className="product-name">
              {proData?.productName}
            </Typography>
            <p className="special-price-text">Special price</p>
            <p className="price-container">
              {proData?.specialPrice && (
                <span className="offer-price">₹{calculateWithDicTotal(proData).toFixed(2)}</span>

              )}
              {!proData?.specialPrice && (
                <span className="actual-price">
                  <span className="offer-price">₹{calculateWithDicTotal(proData).toFixed(2)}</span>
                </span>
              )}
              <span className="actual-price">
                <s>₹{calculateTotal(proData).toFixed(2)}</s>
              </span>
              <span className="off-percentage">{percentageVal(proData)}% Off</span>
            </p>
            <p className="prod-description">{proData?.description}</p>
            <Divider />

            <div>
              <p className="cust-text">Delivery <s className="actual-price">₹50</s>  <span className="off-percentage">FREE</span></p>
            </div>

            <div>
              <p className="cust-text">Weight</p>
              {proData?.weight?.map((w) => (
                <Button
                  size="small"
                  onClick={() => setGetWeight(w?.weight)}
                  color={w?.weight === getWeight ? "success" : "primary"}
                  variant={w?.weight === getWeight ? "contained" : "outlined"}
                  className="gd-button"
                >
                  {w.weightLabel}
                </Button>
              ))}
            </div>

            <div>
              <p className="cust-text">Subscription system</p>
              {proData?.subscriptionSystem?.map((ss) => (
                <Button
                  size="small"
                  onClick={() => setGetSubscriptionSystem(ss)}
                  color={
                    ss?.repeat === getSubscriptionSystem?.repeat
                      ? "success"
                      : "primary"
                  }
                  variant={
                    ss?.repeat === getSubscriptionSystem?.repeat
                      ? "contained"
                      : "outlined"
                  }
                  className="gd-button"
                >
                  {ss?.repeat}
                </Button>
              ))}
              <ul style={{ color: "#555555" }}>
                {/* {getSubscriptionSystem?.repeat=="Per-Day"&& 
              <li>Extra off {calculateOff(getSubscriptionSystem).toFixed(2)}
              
              </li>} */}
                <li>Extra off {calculateOff(getSubscriptionSystem).toFixed(2)}</li>
                <li>{getSubscriptionSystem?.savings}</li>
                <li>{getSubscriptionSystem?.exclusiveOffers}</li>
                <li>{getSubscriptionSystem?.convenience}</li>
                <li>{getSubscriptionSystem?.description}</li>
              </ul>
            </div>

            {proData?.fullDescription && <Accordion style={{ marginTop: 20 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">More details</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ color: "#555555" }}>
                <HtmlReactParserComponent
                  htmlContent={proData?.fullDescription}

                />

              </AccordionDetails>
            </Accordion>}
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};

export default ProductDescription;
