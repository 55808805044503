import React, { useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';

 import ColorPic from '../../../components/ColorPic'

function EditorCustomizedToolbarOption() {
    const [title, setTitle] = useState("");

  const [editorState, setEditorState] = useState(() => 
    EditorState.createEmpty()
  );

  const handleEditorChange = (newEditorState) => {
    console.log('newEditorState',draftToHtml(convertToRaw(editorState.getCurrentContent())))
    setEditorState(newEditorState);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        let formData={
            title:title,
            content:draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }
        let URL="https://app-hvpdly3esq-uc.a.run.app/v1/api/createBlog"
      const response = await axios.post(URL, formData); // Replace with your actual endpoint
      console.log('Data saved successfully:', response.data);
      // Reset editor state after successful save
      setEditorState(EditorState.createEmpty()); 
      setTitle("")

    } catch (error) {
      console.error('Error saving data:', error);
      // Handle errors appropriately (e.g., display an error message to the user)
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
  };

  return (
    <div>
      <h1>Post your blog</h1>
      <label>
        Title: <input type="text" onChange={handleChange} value={title} />
      </label>
      <div>
      Content: 
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        editorStyle={{ border: "1px solid black" ,backgroundColor:"#fff",padding:"10px"}}
    toolbar={{
      colorPicker: { component: ColorPic },
    }}
      />
      </div>

      <button onClick={handleSubmit} className="add-to-cart-button">POST BLOG</button>
    </div>
  );
}

export default EditorCustomizedToolbarOption;

