import React, { useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Navigation from "./Navigation";
import StickyLayout from "./StickyLayout";
import ProductList from "./container/microGreens/product/ProductList";
// import MicrogreensTable from "./Microgreens";
import ProductDescriptionS from "./container/microGreens/product/ProductDescriptionS";
// import ProductNavigation from './ProductNavigation';
import CustomerInfoForm from "./CustomerInfoForm";
import About from "./common/Aboutus";
import Blog from "./container/microGreens/blog/Blog";
import TextEditor from "./container/microGreens/blog/TextEditor";
import Benefits from "./container/microGreens/benefits/Benefits";
import { fetchMicrogreen } from "./store/reducer/Home";
import CustomerForm from "./container/microGreens/customer/CustomerForm";
import OrderSummary from "./container/microGreens/orders/Summery";
import OrderSuccess from "./container/microGreens/orders/OrderSuccess";
const products = [
  {
    id: 1,
    name: "Organic Sunflower Microgreens",
    price: 199.99,
    description:
      "Organic Sunflower Microgreens are a nutritional powerhouse packed with vitamins, minerals, and antioxidants. These tiny greens offer a burst of flavor and a boost of health benefits to your meals.",
    image:
      "https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg",
  },
  {
    id: 2,
    name: "Pea Shoot Microgreens",
    price: 149.99,
    description: `Pea Shoot Microgreens are a delicious and nutritious addition to any diet. Here's a description highlighting their key features:
    Pea Shoot Microgreens: A Burst of Fresh Flavor & Nutrition
    Delicate & Sweet: Enjoy the mild, sweet flavor of these tender greens.
    Nutrient-Rich: Packed with vitamins, minerals, and antioxidants for a healthy boost.   
    Versatile: Add them to salads, sandwiches, soups, stir-fries, or even smoothies.   
    Easy to Grow: Perfect for home gardens or small-scale farming.`,
    image:
      "https://www.microgreen-shop.com/wp-content/uploads/2021/08/Design-ohne-Titel-20.png",
  },
  {
    id: 3,
    name: "Radish Microgreens",
    price: 129.99,
    description:
      "Experience a burst of spicy flavor with Radish Microgreens! These vibrant greens offer a peppery kick that adds a unique zing to salads, sandwiches, soups, and more. Packed with vitamins, minerals, and antioxidants, Radish Microgreens are a delicious and nutritious way to enhance your meals. Whether you're a home cook or a professional chef, these versatile greens will add a touch of culinary creativity to any dish.",
    image:
      "https://t4.ftcdn.net/jpg/03/22/08/87/360_F_322088708_evZX37aKyhdcgBX71ld4ljLjfIomIGDn.jpg",
  },
  {
    id: 4,
    name: "Organic Sunflower Microgreens",
    price: 199.99,
    description: `Enjoy the delicate, nutty flavor of Organic Sunflower Microgreens. These nutrient-rich sprouts are packed with vitamins, minerals, and antioxidants, making them a delicious and healthy addition to your meals. Use them as a vibrant garnish, a flavorful salad topper, or even in homemade pesto. Grown organically, these microgreens offer a burst of fresh, natural goodness to every dish.
   <br> Flavor: Delicate and nutty<br>
Nutritional Benefits: Rich in vitamins, minerals, and antioxidants<br>
Versatility: Suitable for various culinary applications <br>
Organic Growing Practices: Emphasizes the organic nature of the product`,
    image:
      "https://thumbs.dreamstime.com/b/microgreens-sprouts-healthy-fresh-microgreens-sprouts-healthy-fresh-food-169093088.jpg",
  },
  // Add more products as needed
];

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Shop", path: "/shop" },
  { label: "Microgreens", path: "/shop/microgreens" },
  { label: "Details", path: "/shop/microgreens/details" },
];
const App = () => {
  const dispatch = useDispatch();

  const { mData } = useSelector((state) => state.apiData);

  useEffect(() => {
    dispatch(fetchMicrogreen());
  }, []);

  const handleNavigate = (path) => {
    console.log("Navigating to:", path);
    // Use react-router's `useNavigate` or another navigation logic here
    // Example: navigate(path) if using `useNavigate` hook
  };

  return (
    <Router>
      <StickyLayout>
        <div>
          <Routes>
            <Route path="/" element={<ProductList products={mData} />} />
            <Route
              path="/products"
              element={<ProductList products={mData} />}
            />
            <Route
              path="/productsDetails/:id"
              element={<ProductDescriptionS />}
            />
            <Route path="/benefits" element={<Benefits />} />
            <Route path="/customerInfoForm" element={<CustomerInfoForm />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<CustomerInfoForm />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/postBlog" element={<TextEditor />} />
            <Route path="/customerinfo" element={<CustomerForm />} />
            <Route path="/orderSummary" element={<OrderSummary />} />
            <Route path="/orderSuccess" element={<OrderSuccess />} />

            

          </Routes>
        </div>
      </StickyLayout>
    </Router>
  );
};

export default App;
