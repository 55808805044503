import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

const About = () => {

  const location = useLocation();

  useEffect(() => {
    window.dataLayer.push({
      'event': 'pageview',
      'page': location.pathname + location.search + location.hash
    });
  }, [location]); 

    return (
      <div className="about-container">
        <h1
          className="about-title"
          title="Treelix Agritech & Industries Private Limated"
        >
          TREELIX AGRITECH AND INDUSTRIES PRIVATE LIMITED
        </h1>
        <h2>A Greener Tomorrow, Today</h2>
  
        <p>
          Treelix Agritech & Industries Private Limated is a pioneering force in
          the realm of sustainable agriculture, dedicated to cultivating a greener
          future. With a strong focus on indoor and outdoor plants, fruits,
          vegetables, and innovative micro-greens, we strive to nourish both
          individuals and the planet.
        </p>
  
        <h3>Our Vision</h3>
        <p>
          Our vision is to foster a healthier and more sustainable India. By
          promoting eco-friendly practices and reducing plastic waste, we aim to
          make a positive impact on the environment.
        </p>
        <h3>Our Mission</h3>
        <p>
          <b>Cultivate Excellence: </b> To produce high-quality, organic produce
          that nourishes both body and soul.
          <br />
          <b>Innovate for Sustainability: </b> To explore innovative agricultural
          techniques that minimize environmental impact.
          <br />
          <b>Community Empowerment: </b> To empower communities by providing
          access to fresh, healthy food and sustainable living solutions.
          <br />
        </p>
        <h3>Our Commitment</h3>
        <b>We are committed to:</b>
        <p>
          <b>Sustainability: </b> Adopting sustainable farming practices to
          preserve natural resources.
          <br />
          <b>Quality: </b>
          Delivering premium-quality products that meet the highest standards.
          <br />
          <b> Customer Satisfaction: </b> Providing exceptional customer service
          and building long-lasting relationships.
        </p>
  
        <p>
          <h3>
            <b>Our Products</b>
          </h3>
          <b>Micro-Greens: </b>
          A powerhouse of nutrients, our micro-greens are grown in controlled
          environments to maximize flavor and nutritional value.
          <br />
          <b>Indoor and Outdoor Plants: </b>
          A wide range of plants to beautify your spaces and purify the air.{" "}
          <br />
          <b>Fruits and Vegetables: </b> Fresh, organic produce, sourced locally
          and sustainably.
        </p>
        <h3>
          <b>Our Future</b>
        </h3>
        <p>
          As we continue to grow, we are excited to expand our operations to
          Mumbai, Bengaluru, Kolkata, Ranchi, and Delhi. By reaching a wider
          audience, we can inspire more people to embrace sustainable living and
          contribute to a greener planet.
        </p>
  
        <p>Join us on our journey to a healthier, greener future.</p>
        <div className="contact-info">
          <div className="">
            <img
              src="/new-removebg-preview.png" // Replace with your profile image
              alt="treelixlogo"
              className="profile-pic aboutlogo"
            />
            {/* <span className="profile-name">Treelix</span> */}
          </div>
          <p> Aparna Pathak</p>
          <p> aparna@treelix.in</p>
        </div>
      </div>
    );
    {
  
    }
  };

  export default About;
