import React from "react";
import parse from "html-react-parser";

const HtmlReactParserComponent = ({ htmlContent, title }) => {
  return (
    <div className="blogSubContainer">
      <h3>{title}</h3>
      {parse(htmlContent)}
    </div>
  );
};

export default HtmlReactParserComponent;
